/**
 * These slugs may _never_ have a DBA logo
 */
const forbiddenSlugs = [
    "about-us",
    "our-team",
    "bay-equity-careers",
    "bay-equity-news/all-about-be",
    "contact-us",
];

/**
 * Are any of the members of the provided array on the naughty list?
 * @param {String[]} active
 * @returns Boolean
 */
function dbaForbidden(active = []) {
    return !!active.find(a => {
        return forbiddenSlugs.includes(a);
    });
}

export default dbaForbidden;
