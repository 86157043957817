import React, { Component } from "react";

import styles from "./styles.module.less";

class AccessibilityLink extends Component {
    async componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://bayequity.usablenet.com/pt/start";
        script.async = true;
        document.body.appendChild(script);
    }

    render() {
        const { title = "Enable Accessibility" } = this.props;

        const handleClick = e => {
            e.preventDefault();
            if (!!window.enableUsableNetAssistive) {
                window.enableUsableNetAssistive();
            }
        };

        return (
            <div className={styles.container}>
                <div className={styles.accessibilityLink}>
                    <a
                        href="_blank"
                        rel="noopener noreferrer"
                        className="UsableNetAssistive"
                        onClick={handleClick}>
                        {title}
                    </a>
                </div>
            </div>
        );
    }
}

export default AccessibilityLink;
